(function($) {
  $(function() {
    $('.js-field-switcher-subject select').on('change', function() {
      var value = String($(this).val()).toLowerCase();

      $('.js-field-switched').hide().find('input, select, textarea').prop('disabled', true);
      if (value) {
        var classname = value.indexOf('rejoindre') !== -1 ? 'join' : (value.indexOf('projet') !== -1 ? 'project' : 'other');
        $('.js-field-switched-subject-' + classname).show().find('input, select, textarea').prop('disabled', false).closest('.js-group-subject-switched').show();

        $('.js-form-saparator, .js-form-gdpr, .js-form-submit').show().find('input, select, textarea').prop('disabled', false);
      }
      else {
        $('.js-form-saparator, .js-form-gdpr, .js-form-submit').hide().find('input, select, textarea').prop('disabled', true);
      }

      $('.js-group-subject-switched').each(function() {
        var $this = $(this);
        if ($this.find('.js-field-switched:visible').length > 0) {
          $this.show();
        }
        else {
          $this.hide();
        }
      });

      if (value.indexOf('projet') !== -1) {
        $('.js-field-switcher-project-type select').trigger('change');
      }
    }).trigger('change');

    $('.js-field-switcher-project-type select').on('change', function() {
      var value = String($(this).val()).toLowerCase();

      if (value.indexOf('construction') !== -1) {
        $('.js-field-switched-project-type-construction').show().find('input, select, textarea').prop('disabled', false);
      }
      else {
        $('.js-field-switched-project-type-construction').hide().find('input, select, textarea').prop('disabled', true);
      }
    }).trigger('change');
  });
})(jQuery);
