$(function() {
  var $body     = $('body'),
    $header     = $('#header'),
    $navigation = $('#navigation'),
    $button     = $('#navigation-button'),
    $links      = $('.js-navigation-link, .js-navigation-sublink'),
    was_mobile = $button.css('display') === 'none' ? false : true,
    now_mobile = was_mobile
    scrollTop = 0,
    didResize = true;

  if (!$header.length) return true;

  $window.on('resize', function () {
    didResize = true;
  });

  $button.on('click', function(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!now_mobile && !was_mobile) return;

    var navigation_visible = $navigation.attr('aria-hidden') === 'true' ? false : true;

    $button.attr('aria-expanded', !navigation_visible);
    $navigation.attr('aria-hidden', navigation_visible);
    $header.toggleClass('l-header--navigation-expanded', !navigation_visible);

    if(navigation_visible) {
      $body.removeClass('no-scroll').css('top', '');
      $window.scrollTop(scrollTop);
    }
    else {
      scrollTop = $window.scrollTop();
      $body.addClass('no-scroll').css('top', -scrollTop + 'px');
    }
  });

  // $links.on('click', function() {
  //   var $this = $(this);

  //   if ($this.parent().hasClass('l-navigation__item--withsub') || $this.parent().hasClass('l-navigation__subitem--withside')) {
  //     return;
  //   }

  //   if ($navigation.attr('aria-hidden') === 'false') {
  //     $button.trigger('click');
  //   }
  // });

  $(document).click(function(e) {
    if ($navigation.attr('aria-hidden') === 'false' && e.target.tagName.toLowerCase() !== 'a') {
      $button.trigger('click');
    }
  });

  $(document).keyup(function(e) {
    if (e.keyCode == 27 && $navigation.attr('aria-hidden') === 'false') {
      $button.trigger('click');
    }
  });

  // $document.on('click', function(event) {
  //   if(Modernizr.touchevents) {
  //     var $this = $(event.target);
  //
  //     if($this.hasClass('js-navigation-link')) {
  //       if(!$this.parent().hasClass('is-active')) {
  //         event.preventDefault();
  //
  //         $links.parent().removeClass('is-active');
  //
  //         $this.parent().addClass('is-active');
  //       }
  //     } else {
  //       $links.parent().removeClass('is-active');
  //     }
  //   }
  // });

});
