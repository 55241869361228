function initAutocompletes() {
  var collection = document.getElementsByClassName('js-places-address');
  for (var i = 0; i < collection.length; i++) {
    initAutocomplete(collection[i]);
  }
}

function initAutocomplete(element) {
  // Enable autocompletion
  var autocomplete = new google.maps.places.Autocomplete(element, {
    componentRestrictions: {country: 'fr'},

    // https://developers.google.com/maps/documentation/places/web-service/autocomplete#types
    types: ['postal_code', 'locality'],

    // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult.formatted_address
    fields: ['address_components', 'geometry']
  });

  // Listen autocompleter
  google.maps.event.addListener(autocomplete, 'place_changed', function () {
    var place = autocomplete.getPlace();

    if (place.geometry) {
      var address_components = {};
      for (var i=0; i<place.address_components.length; i++) {
        for (var j=0; j<place.address_components[i].types.length; j++) {
          address_components[place.address_components[i].types[j]] = place.address_components[i]['long_name'];
        }
      }

      var address = {
        // name: [address_components.street_number, address_components.route].filter(function(v) { return v !== undefined }).join(' '),
        // postcode: address_components.postal_code,
        city: address_components.locality,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      fillAutocompleteInputs(element, address);
    }
  });
}

function fillAutocompleteInputs(element, address) {
  var $element = $(element);
  $element.siblings('.js-places-latitude').val(address.lat);
  $element.siblings('.js-places-longitude').val(address.lng);
}
