//= require slick-carousel/slick/slick.js

$(function() {
  var conf = {
    projects: {
      centerMode: true,
      centerPadding: '340px',
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      responsive: [
        {
          breakpoint: 780, // jusqu'à 780 (regular)
          settings: {
            centerPadding: '10px',
          }
        },
        {
          breakpoint: 960, // jusqu'à 960 (large)
          settings: {
            centerPadding: '110px',
          }
        },
        {
          breakpoint: 1200, // jusqu'à 1200 (xlarge)
          settings: {
            centerPadding: '170px',
          }
        },
        {
          breakpoint: 1440, // jusqu'à 1440 (xxlarge)
          settings: {
            centerPadding: '170px',
          }
        },
      ]
    },
    advices: {
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 780, // jusqu'à 780 (regular)
          settings: {
            variableWidth: false,
          }
        }
      ]
    }
  };

  $('.c-slick').each(function() {
    // return;
    var $this = $(this);

    var component = $this.data('slick-component');
    var $component = $this.closest('.c-' + component);
    var slick_class = 'c-' + component + '--slick';
    if ($component.hasClass(slick_class)) return;
    $component.addClass(slick_class);

    var component_conf = conf[component];
    if ($component.hasClass('c-projects--photos')) {
      component_conf.infinite = false;
    }

    $this.slick($.extend({
      dots: false,
      infinite: false,
      speed: 300,
      prevArrow: $component.find('.c-slick__prev'),
      nextArrow: $component.find('.c-slick__next'),
    }, component_conf));
  })
});
