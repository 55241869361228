//= require magnific-popup/dist/jquery.magnific-popup.js

(function($) {
  $(function() {
    $.extend(true, $.magnificPopup.defaults, {
      tClose: 'Fermer',
      tLoading: 'Chargement…',
      gallery: {
        tPrev: 'Précédent',
        tNext: 'Suivant',
        tCounter: '%curr% sur %total%'
      },
      image: {
        tError: '<a href="%url%">L\'image</a> ne peut pas être chargée.'
      },
      ajax: {
        tError: '<a href="%url%">Le contenu</a> ne peut pas être chargé.'
      }
    });

    $('.js-photo').magnificPopup({
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
      },
    });
  });
})(jQuery);
